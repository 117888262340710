export const ChallengeLB = ({rankings}: {rankings: any}) => {
    return ( <div className="Account-settings-container Leaderboard-container Leaderboard-container-Less-padding"
        style={{width: '800px', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', padding: '0', borderRadius: '5px', border: '3px solid #7e8ab3'}}>
       {/*{LeaderboardFilterBar(snapshotFilter, setSnapshotFilter)}*/}

       <ul className="Heading">
           <li style={{paddingLeft: '10px'}}><span>Rank</span></li>
           <li><span>User</span></li>
           <li><span>Completed</span></li>
           <li><span>Prize</span></li>
       </ul>
       {
           rankings.slice(3).map((entry: any, index: number) => {
               return (<ul>
                   <li><p>{index + 4}</p></li>
                   <li style={{display: 'flex', justifyContent: 'left'}}>
                       <img width={30} style={{borderRadius: '50%', padding: '0 15px'}} src={entry?.image ?? 'https://btcs.gg/newcoin.svg'} />
                       {entry.username}...
                   </li>
                   <li>{entry.completed}</li>
                   <li>${entry.prize ?? '-'}</li>
               </ul>)
           })
       }
   </div>
    )
}