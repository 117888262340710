export const PrevMonthChallengesLB = [
    {username: 'Smokey1', completed: 64, earned: 5532.69, prize: '5000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'ElderBTC', completed: 54, earned: 3916.00, prize: '2500', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Oopastae', completed: 38, earned: 3905.00, prize: '1250', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Reelss', completed: 15, earned: 1036.66, prize: '750', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rekehh', completed: 11, earned: 672.00, prize: '500', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Bigcockdude', completed: 6, earned: 427.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gun', completed: 3, earned: 230.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'TokenzIRL', completed: 3, earned: 200.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: '999', completed: 2, earned: 750.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Georgedunphy', completed: 2, earned: 285.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: '555', completed: 2, earned: 1277.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Hallowtide', completed: 2, earned: 400.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'WeInThisBih', completed: 2, earned: 170.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'CAM1', completed: 1, earned: 30.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Remedys', completed: 1, earned: 50.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
]

export const PrevMonthWagerLB = [
    {username: '999', totalWagered: 5832766.48, prize: 7500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Bru', totalWagered: 676902.53, prize: 2500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Oop', totalWagered: 608016.86, prize: 1000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 477708.43, prize: 500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Hat', totalWagered: 367918.48, prize: 350, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Vol', totalWagered: 303456.53, prize: 275, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Smo', totalWagered: 268893.73, prize: 250, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'dic', totalWagered: 252717.55, prize: 225, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'mtu', totalWagered: 252213.79, prize: 200, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Emm', totalWagered: 240303.12, prize: 175, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Eld', totalWagered: 197685.29, prize: 170, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jol', totalWagered: 126688.81, prize: 165, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Sat', totalWagered: 121960.17, prize: 160, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Shr', totalWagered: 121354.35, prize: 155, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN', totalWagered: 101272.62, prize: 150, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Nic', totalWagered: 85496.21, prize: 145, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'CAM', totalWagered: 83825.75, prize: 140, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Pre', totalWagered: 80006.46, prize: 135, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Tok', totalWagered: 63598.81, prize: 130, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'geo', totalWagered: 52319.29, prize: 125, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Kil', totalWagered: 52275.99, prize: 120, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rek', totalWagered: 50423.94, prize: 115, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'rn2', totalWagered: 45715.36, prize: 110, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'cur', totalWagered: 42913.09, prize: 105, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'asu', totalWagered: 42313.40, prize: 100, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Tho', totalWagered: 41595.92, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Aus', totalWagered: 40043.39, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jos', totalWagered: 37434.71, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'mim', totalWagered: 36428.94, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Ree', totalWagered: 36234.97, prize: 0, image: 'https://btcs.gg/newcoin.svg'}
]